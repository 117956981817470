import React, { useEffect, useState } from 'react'
import { Link, navigate } from 'gatsby';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { useSelector, useDispatch } from 'react-redux';

import SearchIcon from '@material-ui/icons/Search';
import appLogo from '../../../content/assets/appLogo.png';
import facebookLogo from '../../../content/assets/facebook_logo.png';
import instagramLogo from '../../../content/assets/instagram_logo.png';
import lineLogo from '../../../content/assets/line_logo.png';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { getCategories } from '../../actions/questionnaire';

const useStyles = makeStyles(theme => ({
  appBarMobile: {
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 70,
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
    display: 'none',
    padding: '0px 15px',
    maxWidth: 984,
    width: '100%',
    borderBottom: '1px solid gray'
  },
  appBar: {
    alignItems: 'center',
    height: 70,
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    display: 'flex',
    maxWidth: 984,
    width: '100%',
    borderBottom: '1px solid gray'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    maxWidth: 984,
    width: '100%',
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      padding: '0px 15px'
    },
  },
  menuContainer: {
    marginLeft: 'auto',
  },
  appIcon: {
    marginRight: 10,
    width: '60px',
    height: '60px',
  },
  menuIcon: {
    fontSize: '28px'
  },
  appText: {
    color: '#4a89bc',
    fontSize: 20,
    fontWeight: 'bold'
  },
  red: {
    color: 'red'
  },
  searchButton: {
    marginLeft: 15,
    visibility: 'hidden'
  },
  menuButton: {
    color: 'darkslategrey',
    fontWeight: 'bold'
  },
  contactFooter: {
    background: '#3074b4',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center'
  },
  contactContainer: {
    maxWidth: 984,
    width: '100%',
    padding: '20px 0px',
    display: 'flex',
    alignItems: 'center',
  },
  socialName: {
    fontSize: 18,
    color: 'white',
    fontWeight: 'bold'
  },
  socialLogo: {
    cursor: 'pointer',
    width: 40,
    height: 40,
    marginRight: 15
  },
  socialContainer: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down('sm')]: {
      padding: 10
    },
  },
  aboutusFooter: {
    background: '#b0cae3',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center'
  },
  aboutusText: {
    color: 'white',
    fontWeight: 'bold',
    whiteSpace: 'pre'
  },
  detailContainer: {
    [theme.breakpoints.down('sm')]: {
      padding: 10
    },
  },
  drawer: {
    background: '#3074b4',
    color: 'white'
  }
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const dispatch = useDispatch();
  const categories = useSelector(state => state.questionnaire.categories);
  const user = useSelector(state => state.session.user);

  useEffect(() => {
    dispatch(getCategories());
  }, []);

  function login() {
    const { pathname, search } = window.location;
    localStorage.setItem('returnUri', `${pathname}${search}`);
    navigate('/login');
  }

  return (
    <>
      <Drawer
        anchor="left"
        classes={{ paper: classes.drawer }}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <List>
          {
            !user && (
              <>
                <ListItem button onClick={login}>
                  <ListItemText primary='เข้าสู้ระบบ' />
                </ListItem>
                <Divider />
              </>
            )
          }
          <ListItem button onClick={() => navigate('/')}>
            <ListItemText primary='หน้าแรก' />
          </ListItem>
          <ListItem button onClick={() => navigate('/blogs')}>
            <ListItemText primary='บทความ' />
          </ListItem>
          <ListItem button onClick={() => navigate('/questionnaires')}>
            <ListItemText primary='แบบประเมิน' />
          </ListItem>
          <ListItem button onClick={() => navigate('/products')}>
            <ListItemText primary='สินค้าและบริการ' />
          </ListItem>
          <ListItem button onClick={() => navigate('/locations')}>
            <ListItemText primary='รพ/ศูนย์ พันธมิตร' />
          </ListItem>
          <ListItem button onClick={() => navigate('/categories')}>
            <ListItemText primary='ปรึกษาผู้เชี่ยวชาญ' />
          </ListItem>
        </List>
      </Drawer>
      <Grid
        container
        spacing={0}
        direction="column"
        style={{ minHeight: '100vh', alignItems: 'center' }}
      >
        <Grid item className={classes.appBarMobile}>
          <IconButton onClick={() => setOpenDrawer(true)}>
            <MenuIcon className={classes.menuIcon} />
          </IconButton>
          <p className={classes.appText}>เพราะเรา...<span className={classes.red}>รัก</span>คุณ</p>
          <SearchIcon className={classes.searchButton} />
        </Grid>
        <Grid item className={classes.appBar}>
          <Link to="/">
            <img src={appLogo} className={classes.appIcon} alt="apps logo" />
          </Link>
          <p className={classes.appText}>เพราะเรา...<span className={classes.red}>รัก</span>คุณ</p>
          <Box display="flex" alignItems="center" className={classes.menuContainer}>
            <MenuButton onClick={() => navigate('/')}>หน้าแรก</MenuButton>
            <MenuButton onClick={() => navigate('/blogs')}>บทความ</MenuButton>
            <MenuButton onClick={() => navigate('/questionnaires')}>แบบประเมิน</MenuButton>
            <MenuButton onClick={() => navigate('/products')}>สินค้าและบริการ</MenuButton>
            <MenuButton onClick={() => navigate('/locations')}>รพ/ศูนย์ พันธมิตร</MenuButton>
            <MenuButton onClick={() => navigate('/categories')}>ปรึกษาผู้เชี่ยวชาญ</MenuButton>
            {!user && <Button color="primary" variant="contained" onClick={login}>เข้าสู้ระบบ</Button>}
            {/* <SearchIcon className={classes.searchButton} /> */}
          </Box>
        </Grid>
        <Grid item className={classes.container}>
          {children}
        </Grid>
      </Grid>
      <div className={classes.contactFooter}>
        <div className={classes.contactContainer}>
          <Grid container>
            <Grid item xs={12} sm={6} className={classes.socialContainer}>
              <a href="https://www.facebook.com/moronline.me" target="_blank" rel="noreferrer">
                <img className={classes.socialLogo} src={facebookLogo} />
              </a>
              <a href="https://www.instagram.com/moronline.me/" target="_blank" rel="noreferrer">
                <img className={classes.socialLogo} src={instagramLogo} />
              </a>
              <a href="https://lin.ee/3RK3iHI" target="_blank" rel="noreferrer">
                <img className={classes.socialLogo} src={lineLogo} />
              </a>
              <span className={classes.socialName}>@moronline</span>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.socialContainer}>
              <MailOutlineIcon style={{ color: 'white', fontSize: 36, marginRight: 12 }} />
              <span className={classes.socialName}>Contact Us: <Link to="mailto:contact@virtualmed.co.th" >contact@virtualmed.co.th</Link></span>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={classes.aboutusFooter}>
        <div className={classes.contactContainer}>
          <Grid container>
            <Grid item xs={12} sm={4} className={classes.detailContainer}>
              <h3>About MorOnline</h3>
              <p className={classes.aboutusText}>หมอออนไลน์ เพราะเรา...รักคุณ</p>
              <p className={classes.aboutusText}>{
                `\tเป็น Platform ที่มีความตั้งใจที่จะพัฒนา\nโครงสร้างสาธารณสุขเมืองไทยอย่างยั่งยืน อัน\nเป็นสิ่งที่สำคัญในการพัฒนาประเทศ เรามีจุดยืน\nในการใช้เทคโนโลยีเพื่อให้เกิดประโยชน์สูงสุดกับ\nคนไทย`
              }</p>
            </Grid>
            <Grid item xs={12} sm={4} className={classes.detailContainer}>
              <h3>หมวดหมู่</h3>
              <ul>
                <li className={classes.aboutusText}>
                  <Link to={'/blogs'} className={classes.aboutusText}>บทความทั้งหมด</Link>
                </li>
                <li className={classes.aboutusText}>
                  <Link to={'/blogs'} className={classes.aboutusText}>แบบประเมินทั้งหมด</Link>
                </li>
                {
                  categories.map((category) => (
                    <li key={category.id} className={classes.aboutusText}>
                      <Link to={`/blogs?category_id=${category.id}`} className={classes.aboutusText}>{category.name}</Link>
                    </li>
                  ))
                }
              </ul>
            </Grid>
            <Grid item xs={12} sm={4} className={classes.detailContainer}>
              <h3>ความปลอดภัยและความเป็นส่วนตัว</h3>
              <ul>
                <li className={classes.aboutusText}>
                  <Link to="https://moronline.freshdesk.com/support/solutions/articles/72000520079-privacy-policy" className={classes.aboutusText}>นโยบายความเป็นส่วนตัว</Link>
                </li>
                <li className={classes.aboutusText}>
                  <Link to="https://moronline.freshdesk.com/en/support/solutions/articles/72000520080-term-of-service" className={classes.aboutusText}>ข้อตกลงการใช้งาน</Link>
                </li>
                <li className={classes.aboutusText}>
                  <Link to="https://moronline.freshdesk.com/support/solutions/articles/72000606325-cookie-policy" className={classes.aboutusText}>นโยบายการใช้ Cookie</Link>
                </li>
              </ul>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}

const MenuButton = ({ children, onClick }) => {
  const classes = useStyles();
  return (
    <Button className={classes.menuButton} onClick={onClick}>{children}</Button>
  )
}
export default MainLayout;

